import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import * as Markdown from 'react-markdown'
import Reputation from './reputation'
import WhyUs from './whyus'
import ContactInfo from './contact-info'
import ReviewsSlider from './reviews-slider'

const MainContainerOuter = styled.div`
  width: 100%;
  background: white;
  margin-bottom: 1em;
`

const SidebarTitle = styled.div`
  background: #f6f6f6;
  padding: 1em;
  h3 {
    margin: 0;
  }
`
const SidebarContent = styled.div`
  padding: 1em;
`

const Card = ({ sidebar }) => {
  if (sidebar.content.content.includes('{{Testimonials}}')) {
    return <ReviewsSlider />
  }
  if (sidebar.content.content.includes('{{ReputationBar}}')) {
    return <Reputation />
  }
  if (sidebar.content.content.includes('{{WhyUs}}')) {
    return <WhyUs />
  }
  if (sidebar.content.content.includes('{{ContactInfo}}')) {
    return <ContactInfo />
  }
  return (
    <MainContainerOuter
      className={
        'border-radius shadow-md sidebar-card ' +
        (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : '')
      }
    >
      <SidebarTitle>
        <h3>{sidebar.title}</h3>
      </SidebarTitle>
      <SidebarContent
        dangerouslySetInnerHTML={{ __html: sidebar.content.content }}
      />
    </MainContainerOuter>
  )
}

export default Card

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroArea from '../components/hero-area'
import Card from '../components/card'
import styled from 'styled-components'
import FeaturedItems from '../components/featured-items'

class IndexPage extends React.Component {
  render() {
    const homePageNode = this.props.data.allContentfulPage.edges[0].node

    const MainContentContainer = styled.div``

    const SidebarContainer = styled.div``

    const SidebarColumn = () => {
      const sideBarCards = homePageNode.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    return (
      <Layout>
        <SEO
          title={homePageNode.metaTitle}
          description={homePageNode.metaDescription}
          keywords={['gatsby', 'application', 'react']}
        />
        {homePageNode.heroArea ? (
          <HeroArea heroContent={homePageNode.heroArea} />
        ) : (
          ''
        )}
        {homePageNode.featuredItems ? (
          <FeaturedItems data={homePageNode.featuredItems} />
        ) : (
          ''
        )}

        <div className="container main-content">
          <div className="row">
            <MainContentContainer
              className={homePageNode.sidebar ? 'col-12 col-md-8' : 'col-12'}
              dangerouslySetInnerHTML={{ __html: homePageNode.body.body }}
            />

            {homePageNode.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                <SidebarColumn />
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>
      </Layout>
    )
  }
}
class ComingSoon extends React.Component {
  render() {
    return <h1>Coming soon</h1>
  }
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          featuredItems {
            id
            serviceTitle
            excerpt
            slug
            heroImage {
              fluid(maxWidth: 370) {
                ...GatsbyContentfulFluid
              }
            }
          }
          id
          slug
          pageTitle
          metaTitle
          metaDescription
          body {
            body
          }
          heroArea {
            heroTitle
            description {
              description
            }
            heroStyle
            heroBackground
            customBgColor
            textColor
            primaryActionBtnLabel
            primaryActionBtnLink
            secondaryActionBtnLabel
            secondaryActionBtnLink
            heroImage {
              fluid(maxWidth: 1500) {
                ...GatsbyContentfulFluid
              }
            }
          }
          sidebar {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
  }
`
